import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorAppUserNae = createSelector(orm.AppUserModel);
export const SelectorCompatibilityReportLogNae = createSelector(orm.CompatibilityReportLogModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'AppUser') {
        return SelectorAppUserNae;
    }
        if (schema === 'CompatibilityReportLog') {
        return SelectorCompatibilityReportLogNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'app-user') {
        return SelectorAppUserNae;
    }
        if (schema === 'compatibility-report-log') {
        return SelectorCompatibilityReportLogNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
    }