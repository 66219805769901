import { ORM } from "redux-orm";
import AppUserModel from "./AppUserModel"
import CompatibilityReportLogModel from "./CompatibilityReportLogModel"
import QueueModel from "./QueueModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    AppUserModel,
    CompatibilityReportLogModel,
    QueueModel,
);

export default orm;