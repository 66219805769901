import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "AppUser",
        "schema": "app-user",
        "title": "App User",
        "titlePlural": "App Users",
        "required": [],
        "scopes": []
    },
    {
        "className": "AskQuestion",
        "schema": "ask-question",
        "title": "Ask question",
        "titlePlural": "Ask question",
        "required": [],
        "scopes": []
    },
    {
        "className": "CompatibilityReportLog",
        "schema": "compatibility-report-log",
        "title": "Compatibility report",
        "titlePlural": "Compatibility report",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "AppUser": {
        "className": "AppUser",
        "schema": "app-user"
    },
    "CompatibilityReportLog": {
        "className": "CompatibilityReportLog",
        "schema": "compatibility-report-log"
    },
    "AskQuestion": {
        "className": "AskQuestion",
        "schema": "ask-question"
    }
}