import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "app-user",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "birthDateDay",
        "type": "integer",
        "format": "",
        "title": "birthDateDay",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "birthDateHour",
        "type": "integer",
        "format": "",
        "title": "birthDateHour",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "birthDateLat",
        "type": "number",
        "format": "float",
        "title": "birthDateLat",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "app-user",
        "key": "birthDateLng",
        "type": "number",
        "format": "float",
        "title": "birthDateLng",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "app-user",
        "key": "birthDateLocation",
        "type": "string",
        "format": "",
        "title": "Birth date location",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "birthDateMinute",
        "type": "integer",
        "format": "",
        "title": "birthDateMinute",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "birthDateMonth",
        "type": "integer",
        "format": "",
        "title": "birthDateMonth",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "birthDateTimeZone",
        "type": "integer",
        "format": "",
        "title": "birthDateTimeZone",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "birthDateYear",
        "type": "integer",
        "format": "",
        "title": "birthDateYear",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "fullBirthDate",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "fullBirthDateFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "fullBirthDateWithTime",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "fullBirthDateWithTimeFormatted",
        "type": "string",
        "format": "",
        "title": "Birth date",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "gender",
        "type": "string",
        "format": "",
        "title": "gender",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "app-user",
        "key": "passwordHash",
        "type": "string",
        "format": "",
        "title": "passwordHash",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "uuid",
        "type": "string",
        "format": "",
        "title": "uuid",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "app-user",
        "key": "zodiacSign",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AppUser",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "ask-question",
        "key": "appUser",
        "type": "rel",
        "format": "app-user",
        "title": "appUser",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "ask-question",
        "key": "birthDate",
        "type": "string",
        "format": "",
        "title": "birthDate",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ask-question",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "ask-question",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ask-question",
        "key": "location",
        "type": "string",
        "format": "",
        "title": "location",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ask-question",
        "key": "question1",
        "type": "string",
        "format": "text",
        "title": "question1",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": false,
        "dbType": "",
        "naeType": "text"
    },
    {
        "schema": "ask-question",
        "key": "question2",
        "type": "string",
        "format": "text",
        "title": "question2",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": false,
        "dbType": "",
        "naeType": "text"
    },
    {
        "schema": "ask-question",
        "key": "question3",
        "type": "string",
        "format": "text",
        "title": "question3",
        "additionalProperties": [],
        "description": "",
        "className": "AskQuestion",
        "isDb": false,
        "dbType": "",
        "naeType": "text"
    },
    {
        "schema": "compatibility-report-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "datePartner",
        "type": "string",
        "format": "",
        "title": "datePartner",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "datePartnerFormatted",
        "type": "string",
        "format": "",
        "title": "Partner date",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "dateUser",
        "type": "string",
        "format": "",
        "title": "dateUser",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "dateUserFormatted",
        "type": "string",
        "format": "",
        "title": "User date",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "genderPartner",
        "type": "string",
        "format": "",
        "title": "genderPartner",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "genderUser",
        "type": "string",
        "format": "",
        "title": "genderUser",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "generatedAt",
        "type": "string",
        "format": "date-time",
        "title": "Generated At",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "gptResponse",
        "type": "string",
        "format": "text",
        "title": "Response",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "compatibility-report-log",
        "key": "hash",
        "type": "string",
        "format": "",
        "title": "hash",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "compatibility-report-log",
        "key": "partnerZodiacSign",
        "type": "string",
        "format": "",
        "title": "Partner sign",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "score",
        "type": "integer",
        "format": "",
        "title": "score",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "compatibility-report-log",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "compatibility-report-log",
        "key": "userZodiacSign",
        "type": "string",
        "format": "",
        "title": "User sign",
        "additionalProperties": [],
        "description": "",
        "className": "CompatibilityReportLog",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    }
]