import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './_generated/v3/app/App';
import { useQuery } from '@newageerp/v3.bundles.hooks-bundle';

const AuthWrap = () => {
  const uq = useQuery();
  const authQ = uq.get('auth');

  if (authQ !== '8boC7jXF5LzA61xC7sDKlftuW1M9GS') {
    return <Fragment/>
  }
  return <App/>
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthWrap />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
