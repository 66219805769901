import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle'
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import React, { Fragment, useState } from 'react'
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths'
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
import { Popup } from '@newageerp/v3.bundles.popup-bundle';

export default function UsersMenuItem(props: PropsId) {
    const [doReq, doReqParams] = useURequestRaw<any>(NaePathsMap.post.PluginsAuthUserGenToken);
    const [showPopup, setShowPopup] = useState(false);

    const onClick = () => {
        doReq({
            id: props.id
        }).then(() => {
            setShowPopup(true);
        })
    }

    return (
        <Fragment>
            <Popup title="Token" isPopup={showPopup} onClick={() => setShowPopup(false)}>
                {!!doReqParams.data && <p>
                    {doReqParams.data.token}
                </p>}

            </Popup>
            <MenuItem onClick={onClick}>
                Gen token
            </MenuItem>
        </Fragment>
    )
}
