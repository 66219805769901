
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorAppUserNae, SelectorCompatibilityReportLogNae } from '../models/ormSelectors';
import { useAppUserHookNae } from '../hooks/useAppUserHookNae';
import { useCompatibilityReportLogHookNae } from '../hooks/useCompatibilityReportLogHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'app-user') {
    return useAppUserHookNae;
  }
  if (schema === 'compatibility-report-log') {
    return useCompatibilityReportLogHookNae;
  }
  return selector;
}

export function AppUserEmptyFieldNae(props: PropsLink) {
  const element = useAppUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CompatibilityReportLogEmptyFieldNae(props: PropsLink) {
  const element = useCompatibilityReportLogHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

