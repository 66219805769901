import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import UsersMenuItem from "./Users/UsersMenuItem";

export const PluginsMap: any = {
    ...CustomEditComponentsMap,
    ...CustomViewComponentsMap,
    ...CustomListComponentsMap,

    'custom.user.genToken': UsersMenuItem,
}