import { IAppUserFieldsNae } from './useAppUserHookNae';
import { ICompatibilityReportLogFieldsNae } from './useCompatibilityReportLogHookNae';
import { SelectorAppUserNae,SelectorCompatibilityReportLogNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.AppUser.className) {
            return {
                dataToCheck: SelectorAppUserNae(store.getState()),
                fields: IAppUserFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.CompatibilityReportLog.className) {
            return {
                dataToCheck: SelectorCompatibilityReportLogNae(store.getState()),
                fields: ICompatibilityReportLogFieldsNae,    
            }
        }
        return undefined;
}